import { Grid, QuestionBoxFullWidth } from "../../../components";
import { TitleSkeleton, ParagraphSkeleton } from "../../../components/Skeleton";
import Citations from "../../../components/Citations/Citations";
import {
  MutableRefObject,
  Ref,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { Flavour, Question, QuestionResponse } from "../../../interface";
import TypewriterText from "../../../components/TypewriterText/TypewriterText";
import classNames from "classnames";
import { ButtonScrollDown } from "../../../components/Button/ButtonScrollDown";
import { motion, useScroll, useTransform, useInView, circOut, cubicBezier, useMotionValueEvent } from "framer-motion"
import useMediaQuery from "../../../hooks/useMediaQuery";
import MediaLinksV2 from "../../../components/MediaLinks/MediaLinksV2";
import { Pdf } from "../../../components/Pdf/pdf";
import { Video } from "../../../components/Video/video";

export const XThingsHero = forwardRef(
  (
    {
      templateOrderIndex,
      response,
      loading,
      askQuestion,
      giveFeedback,
      setNextTemplate,
      nextTemplate,
      questions,
      flavours,
    }: {
      templateOrderIndex: number;
      loading: boolean;
      response: QuestionResponse;
      askQuestion: (query: string) => void;
      giveFeedback?: (feedback_type: -1 | 1) => Promise<void>;
      setNextTemplate: React.Dispatch<
        React.SetStateAction<Ref<HTMLDivElement>>
      >;
      nextTemplate: Ref<HTMLDivElement>;
      questions: Question[];
      flavours: Flavour[];
    },
    ref: Ref<HTMLDivElement>
  ) => {
    const { content, followUps, userQuestion, query, assets } = response;
    const [para1Done, setPara1Done] = useState(false);
    const [para2Done, setPara2Done] = useState(false);
    const [para3Done, setPara3Done] = useState(false);
    const [header1Done, setHeader1Done] = useState(false);
    const [header2Done, setHeader2Done] = useState(false);
    const [header3Done, setHeader3Done] = useState(false);
    const [hideQuestion, setHideQuestions] = useState(false);
    const nextTemplateRef = nextTemplate as MutableRefObject<HTMLDivElement>;
    const heroXThingsRef = useRef(null);
    const questionRef = useRef<null | HTMLDivElement>(null);
    const questionIsInView = useInView(questionRef)

    useEffect(() => {
      setNextTemplate(heroXThingsRef);
    }, []);

    console.log(
      "template: 3 Parts Hero",
      `height: ${document.getElementById("xthings-hero")?.getBoundingClientRect().height}`,
      `width: ${document.getElementById("xthings-hero")?.getBoundingClientRect().width}`
    );

    const { scrollYProgress: mainYProgress } = useScroll({
      target: heroXThingsRef,
      offset: ["start center", "end start"],
      // offset: ["center end", "end center"],
    });

    const opacityProgress = useTransform(mainYProgress, [1, 0.6], [0.6, 1]);
    const scaleProgress = useTransform(mainYProgress, [1, 0.6], ["90%", "100%"]);
    const backgroundY = useTransform(mainYProgress, [0.6, 1], ["0%", "30%"]);

    const { scrollYProgress: contentYProgress } = useScroll({
      target: heroXThingsRef,
    });
    const { scrollYProgress: questionYProgress } = useScroll({
      target: heroXThingsRef,
      offset: ["center end", "end center"],
    });

    const y = useTransform(contentYProgress, [0, 1], ["1%", document.body.clientHeight < 2100 ? "-60%" : "-40%"]);
    const questionY = useTransform(questionYProgress, [1, 0], ["-100%", "0%"]);   

    return (
      <motion.div
        id="xthings-hero"
        ref={heroXThingsRef}
        className={classNames("relative desktop:rounded-t-[1.5rem] z-40", {
          ["-mt-[2.5rem] mobile:rounded-t-3xl"]: templateOrderIndex !== 0,
        })}
        style={
          flavours.length - 1 !== templateOrderIndex && questions.length > 1
            ? { y: backgroundY, scale: scaleProgress, opacity: opacityProgress }
            : {}
        }
      >
        <div className="desktop:h-[200vh]">
          <div
            className="bg-gradient-to-b from-[#E0CCFF] from-60% to-white desktop:sticky desktop:top-0 desktop:h-screen mobile:h-full bg-no-repeat bg-center bg-cover relative mobile:rounded-t-3xl desktop:rounded-t-[1.5rem]"
          // style={{ backgroundImage: `url('${content.imgURL}')` }}
          >
            <div className="desktop:h-screen">
              <div className="h-full desktop:min-h-full mobile:rounded-t-3xl desktop:rounded-t-[1.5rem]">
                <motion.div style={useMediaQuery('(min-width: 1280px)') ? { y: y } : {}}>
                  <Grid customClasses="mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0 desktop:!mx-0 gap-x-4">
                    <div className="desktop:mt-[74px] mt-[42px] mobile:col-start-1 desktop:col-start-2 col-span-full mobile:rounded-t-3xl desktop:rounded-t-[1.5rem]">
                      <h2 className="mobile:text-[1rem] desktop:text-lg text-black text-base font-medium uppercase">
                        {query}
                      </h2>
                      <div className="desktop:mt-[36px] mt-[24px]">
                        {!content.header ? (
                          <TitleSkeleton rows={1} />
                        ) : (
                          <h3 className="mobile:text-4xl tablet:text-[40px] text-black text-[40px] font-bold">
                            {content.header}
                          </h3>
                        )}
                      </div>
                    </div>

                    <div className="desktop:flex mobile:mt-[72px] desktop:mt-[72px] desktop:space-x-[50px] mobile:col-start-1 mobile:col-span-full desktop:col-start-2 desktop:col-end-24 justify-between">
                      <div className="mobile:mb-[72px] mobile:p-[44px] tablet:px-[116px] tablet:py-[64px] desktop:p-[44px] rounded-lg mobile:min-h-[200px] desktop:min-h-[322px] desktop:w-[450px] bg-grey-200/95 flex flex-col grow items-start ">
                        {!content.paragraphs[0] ? (
                          <TitleSkeleton />
                        ) : (
                          <h4
                            className="desktop:text-2xl mobile:text-xl font-normal desktop:min-h-20 !text-text-greyHeader"

                          >
                            <TypewriterText
                              hasAllText
                              text={content.paragraphs[0].header}
                              type="strong"
                              isDone={header1Done}
                              setIsDone={setHeader1Done}
                            />
                          </h4>
                        )}
                        {!content.paragraphs[0]?.text ? (
                          <ParagraphSkeleton />
                        ) : (
                          header1Done && (
                            <TypewriterText
                              id="xthings-hero-paragraph-0"
                              className="mobile:text-sm desktop:text-lg font-light desktop:col-span-6 !text-text-greyText"
                              hasAllText={content.paragraphs[0].done}
                              text={content.paragraphs[0].text}
                              isDone={para1Done}
                              setIsDone={setPara1Done}
                            />
                          )
                        )}
                      </div>
                      <div className="mobile:mb-[72px] mobile:p-[44px] tablet:px-[116px] tablet:py-[64px] desktop:p-[44px] rounded-lg bg-grey-200/95 mobile:min-h-[200px] desktop:min-h-[322px] desktop:w-[450px] flex flex-col grow items-start">
                        {!content.paragraphs[1] ? (
                          <TitleSkeleton />
                        ) : (
                          para1Done && (
                            <h5 className="desktop:text-2xl mobile:text-xl font-normal desktop:min-h-20 !text-text-greyHeader">
                              <TypewriterText
                                hasAllText
                                text={content.paragraphs[1].header}
                                type="strong"
                                isDone={header2Done}
                                setIsDone={setHeader2Done}
                              />
                            </h5>
                          )
                        )}
                        {!content.paragraphs[1]?.text ? (
                          <ParagraphSkeleton />
                        ) : (
                          header2Done && (
                            <TypewriterText
                              id="xthings-hero-paragraph-1"
                              className="mobile:text-sm desktop:text-lg font-light desktop:col-span-6 !text-text-greyText"
                              hasAllText={content.paragraphs[1].done}
                              text={content.paragraphs[1].text}
                              isDone={para2Done}
                              setIsDone={setPara2Done}
                            />
                          )
                        )}
                      </div>
                      <div className="mobile:mb-[72px] rounded-lg mobile:p-[44px] tablet:px-[116px] tablet:py-[64px] desktop:p-[44px] bg-grey-200/95 mobile:min-h-[200px] desktop:min-h-[322px] desktop:w-[450px] flex flex-col grow items-start ">
                        {!content.paragraphs[2] ? (
                          <TitleSkeleton />
                        ) : (
                          para2Done && (
                            <h6 className="mobile:text-xl desktop:text-2xl font-normal desktop:min-h-20 !text-text-greyHeader">
                              <TypewriterText
                                hasAllText
                                text={content.paragraphs[2].header}
                                type="strong"
                                isDone={header3Done}
                                setIsDone={setHeader3Done}
                              />
                            </h6>
                          )
                        )}
                        {!content.paragraphs[2]?.text ? (
                          <ParagraphSkeleton />
                        ) : (
                          header3Done && (
                            <TypewriterText
                              id="xthings-hero-paragraph-2"
                              className="mobile:text-sm desktop:text-lg font-light desktop:col-span-6 !text-text-greyText"
                              hasAllText={content.paragraphs[2].done}
                              text={content.paragraphs[2].text}
                              isDone={para3Done}
                              setIsDone={setPara3Done}
                            />
                          )
                        )}
                      </div>
                    </div>
                  </Grid>
                  {para1Done && <Citations metadata={response.metadata} version="white" />}
                  {para1Done && <MediaLinksV2 assets={assets} display="fullwidth" version="white" />}
                </motion.div>
                {/* <div
                  id="button-scroll-container"
                  className="mobile:hidden desktop:grid desktop:grid-cols-24 absolute bottom-16 w-full"
                  >
                  <div
                    className={classNames("desktop:col-start-2 desktop:col-span-full", {
                      ["desktop:translate-y-0 desktop:transition-all desktop:duration-300 desktop:delay-100 desktop:visible"]:
                        para3Done,
                      ["desktop:translate-y-full desktop:invisible desktop:h-0"]:
                        !para3Done,
                    })}
                  >
                    <ButtonScrollDown
                      customClasses="relative"
                      versionColour="image"
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div >
        </div>

        <div className={classNames({ ["!hidden"]: !para3Done })}>
          <motion.div
            ref={questionRef}
            style={useMediaQuery('(min-width: 1280px)') && para3Done && followUps.length > 0 ? { y: questionY } : {}}
            //style={para2Done && questionIsInView ? {y: "-100%", transition: "all 400ms ease-in"} : {y: "0%", visibility: "hidden", transition: "all 300ms ease-out"}}            
            className={classNames("w-full mobile:relative desktop:absolute z-40 mobile:rounded-t-3xl desktop:rounded-t-[1.5rem] mobile:-mt-[3.125rem] desktop:-mt-0",
              {
                ["hidden"]: hideQuestion,
              }
            )}>
            <QuestionBoxFullWidth
              templateOrderIndex={templateOrderIndex}
              style="lightTxt"
              backgroundColour="background-brand"
              followupQuestions={followUps}
              isLoading={!para3Done}
              onSubmit={askQuestion}
              giveFeedback={giveFeedback}
              optionQuestion={userQuestion}
              nextTemplate={nextTemplateRef}
              sethideQuestion={setHideQuestions}
              customMargin="!mx-0"
            />
          </motion.div>
        </div>
      </motion.div >
    );
  }
);
