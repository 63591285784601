import { useNavigate } from "react-router-dom";
import { MovingSpinningCircles } from "../../components/Icons";
import { TypewriterText } from "../../components";
import React, { useEffect, useState, useRef } from "react";
import classnames from "classnames";
import { useConfig } from "../../context/config-context";
import { useChat } from '../../context/chat-context';

export default function InitialNew() {
  const navigate = useNavigate();
  const { config } = useConfig();
  const { questions, setResumed } = useChat();
  const [expanded, setExpanded] = useState(false);
  const [textIsDone, setTextIsDone] = useState(false);
  const pillRef = useRef<HTMLDivElement | null>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    console.log('config', config.HOST)
    const listener = (event: MessageEvent) => {
      console.log(event);
      if (event.origin !== config.HOST) {
        return;
      }

      if (event.data === "OPEN") {        
        setExpanded(true);                

        setTimeout(() => {
          navigate("/streaming");
        }, 600);
      }
    };

    window.addEventListener("message", listener);

    return () => {
      window.removeEventListener("message", listener);
    };
  }, [navigate]);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setWidth(entries[0].contentRect.width);
    });

    if (pillRef.current) {
      observer.observe(pillRef.current);
    }

    return () => {
      pillRef.current && observer.unobserve(pillRef.current);
    };
  }, []);

  return (
    <div     
      className={classnames(
        "w-full h-screen justify-left flex items-end overflow-hidden",
        {
          "justify-left flex items-end bg-black/50 backdrop-blur-md transition-colors duration-1000 desktop:-pr-50px":
            expanded,
        }
      )}
    >      
        <div
          ref={pillRef}
          className={classnames(
            "flex relative content-center items-center z-50 cursor-pointer text-white bg-black/80",
            {                           
              "pl-8 pr-4 py-4 backdrop-blur-[20px] h-[78px] w-[380px] rounded-t-[50px] rounded-br-[50px] text-left fixed bottom-[30px] left-[30px]":
              !expanded,              
              "transition-all duration-500 justify-center mobile:h-screen tablet:h-[550px] desktop:w-[calc(100%-240px)] w-full inset-x-0 desktop:mx-[120px] rounded-t-[50px]":
              expanded, 
            }
          )}
        >
          <div
            className={classnames("flex ml-[5px] mt-[20px] justify-left", {
              block: !expanded,
              hidden: expanded,
            })}
          >
            <MovingSpinningCircles />
          </div>
          <TypewriterText
            id="initial"
            isDone={textIsDone}
            setIsDone={setTextIsDone}
            className={classnames("text-base whitespace-nowrap overflow-hidden", {
              block: !expanded,
              hidden: expanded,
            })}
            hasAllText
            text={
              width > 375
                ? "Hello! I'm a conversational AI. How can I help you today?"
                : "Hello! How can I help you today?"
            }
          />
        </div>      
    </div>
  );
}
