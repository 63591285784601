import { MutableRefObject, Ref, forwardRef, useEffect, useRef, useState } from "react";
import { Grid, QuestionBoxFullWidth } from "../../../components";
import { Flavour, Question, QuestionResponse } from "../../../interface";
import {
  ImageSkeleton,
  TitleSkeleton,
  ParagraphSkeleton,
} from "../../../components/Skeleton";
import classnames from "classnames";
import { ButtonScrollDown } from "../../../components/Button/ButtonScrollDown";
import TypewriterText from "../../../components/TypewriterText/TypewriterText";
import classNames from "classnames";
import { motion, useScroll, useTransform, useInView, circOut, cubicBezier, useMotionValueEvent } from "framer-motion"
import useMediaQuery from "../../../hooks/useMediaQuery";
import { Pdf } from "../../../components/Pdf/pdf";
import Citations from "../../../components/Citations/Citations";
import { Video } from "../../../components/Video/video";
import MediaLinksV2 from "../../../components/MediaLinks/MediaLinksV2";

export const LongInspire = forwardRef(
  (
    {
      templateOrderIndex,
      response,
      loading,
      askQuestion,
      giveFeedback,
      setNextTemplate,
      nextTemplate,
      questions,
      flavours
    }: {
      templateOrderIndex: number;
      loading: boolean;
      response: QuestionResponse;
      askQuestion: (query: string) => void;
      giveFeedback?: (feedback_type: -1 | 1) => Promise<void>;
      setNextTemplate: React.Dispatch<
        React.SetStateAction<Ref<HTMLDivElement>>
      >;
      nextTemplate: Ref<HTMLDivElement>;
      questions: Question[];
      flavours: Flavour[];
    },
    ref: Ref<HTMLDivElement>
  ) => {
    const [version, setVersion] = useState("white");
    const { content, followUps, userQuestion, query, assets } = response;
    const [para1Done, setPara1Done] = useState(false);
    const [para2Done, setPara2Done] = useState(false);
    const [hideQuestion, setHideQuestions] = useState(false);
    const nextTemplateRef = nextTemplate as MutableRefObject<HTMLDivElement>;
    const inspireLongRef = useRef(null);
    const questionRef = useRef<null | HTMLDivElement>(null);
    const questionIsInView = useInView(questionRef)

    useEffect(() => {
      setNextTemplate(inspireLongRef);
    }, []);

    useEffect(() => {
      // TODO - I need to simplyfy and allow more incase there are more than 11 templates in an experience
      if (
        templateOrderIndex === 0 ||
        templateOrderIndex === 3 ||
        templateOrderIndex === 6 ||
        templateOrderIndex === 9
      ) {
        setVersion("white");
      }
      if (
        templateOrderIndex === 1 ||
        templateOrderIndex === 4 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 10
      ) {
        setVersion("dark");
      }
      if (
        templateOrderIndex === 2 ||
        templateOrderIndex === 5 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 11
      ) {
        setVersion("grey");
      }
    }, []);

    console.log(
      "template: Long Inspire",
      `height: ${document.getElementById("long-inspire")?.getBoundingClientRect().height}`,
      `width: ${document.getElementById("long-inspire")?.getBoundingClientRect().width}`
    );


    //ANIMATION
    const { scrollYProgress: mainYProgress } = useScroll({
      target: inspireLongRef,
      offset: ["start center", "end start"],
      // offset: ["center end", "end center"],
    });

    const opacityProgress = useTransform(mainYProgress, [1, 0.6], [0.6, 1]);
    const scaleProgress = useTransform(mainYProgress, [1, 0.6], ["90%", "100%"]);
    const backgroundY = useTransform(mainYProgress, [0.6, 1], ["0%", "30%"]);

    const { scrollYProgress: contentYProgress } = useScroll({
      target: inspireLongRef,
    });
    const { scrollYProgress: questionYProgress } = useScroll({
      target: inspireLongRef,
      offset: ["center end", "end center"],
    });

    const y = useTransform(contentYProgress, [0, 1], ["1%", document.body.clientHeight < 2100 ? "-65%" : "-25%"]);
    const questionY = useTransform(questionYProgress, [1, 0], ["-100%", "0%"]);

    // END ANIMATION

    return (
      <motion.div
        ref={inspireLongRef}
        id="long-inspire"
        className={classnames(
          "relative mobile:rounded-t-3xl desktop:rounded-t-[1.5rem] -mt-[2.5rem] z-40",
          {
            ["bg-white"]: version === "white",
            ["bg-grey-200"]: version === "grey",
            ["bg-grey-1000 text-white"]: version === "dark",
          }
        )}
        style={(flavours.length - 1) !== templateOrderIndex && questions.length > 1 ? { y: backgroundY, scale: scaleProgress, opacity: opacityProgress } : {}}
      >
        <div className="desktop:h-[200vh]">
          <div className="desktop:sticky desktop:top-0 overflow-hidden mobile:rounded-t-3xl desktop:rounded-t-[1.5rem]">
            <motion.div style={useMediaQuery('(min-width: 1280px)') ? { y: y } : {}}>
              <Grid
                customClasses={classnames(
                  "gap-x-4 desktop:!mx-0 relative mobile:rounded-t-3xl desktop:rounded-t-[1.5rem] -mt-[0.5rem] desktop:pb-[2.5rem]",
                  {
                    ["bg-white"]: version === "white",
                    ["bg-grey-200 text-white"]: version === "grey",
                    ["bg-grey-1000 text-white"]: version === "dark",
                  }
                )}
              >
                <motion.div
                  style={useMediaQuery('(min-width: 1280px)') ? {} : {}}
                  id="long-inspire-image-contaier"
                  className="col-start-1 row-start-1 col-span-full h-full z-40"
                >
                  {!content.imgURL ? (
                    <div>
                      <div className="mobile:hidden desktop:block">
                        <ImageSkeleton display="desktop-top" />
                      </div>
                      <div className="desktop:hidden">
                        <ImageSkeleton display="mobile" />
                      </div>
                    </div>
                  ) : (
                    <>
                      {!content.imgURL ? (
                        <div className="mobile:rounded-t-3xl desktop:rounded-t-[1.5rem] mobile:w-full mobile:max-h-full mobile:min-h-[450px] bg-gradient-to-b from-[#8B919A] to-white"></div>
                      ) : (
                        <div className="relative max-h-full mt-[-6px]">
                          <div
                            id="long-inspire-image-overlay"
                            className="bg-gradient-to-r from-[black]/60 from-60% mobile:rounded-t-3xl desktop:rounded-t-[1.5rem] z-30 absolute top-0 left-0 w-full h-full max-h-full mobile:min-h-[400px]"
                          ></div>
                          <div
                            id="long-inspire-image"
                            style={{ backgroundImage: `url('${content.imgURL}')` }}
                            className={`mobile:rounded-t-3xl desktop:rounded-t-[1.5rem] z-20 relative w-full max-h-full mobile:min-h-[400px] bg-cover bg-center bg-no-repeat`}
                          >
                          </div>
                          <div className="absolute h-full w-full overflow-hidden top-0">
                            <Grid customClasses="z-40 gap-x-4 h-full relative !mx-0">
                              <div
                                id="long-inspire-header-content"
                                className="mobile:col-start-1 desktop:col-start-2 mobile:col-span-full mobile:row-start-1 mobile:pt-[4.25rem] tablet:pt-[2.625rem] desktop:pt-[5.25rem] mobile:w-full desktop:w-1/2 mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0"
                              >
                                <h2
                                  id="long-inspire-tagline"
                                  className="text-white mobile:text-[1rem] desktop:text-lg font-medium uppercase"
                                >
                                  {query}
                                </h2>
                                {!content.header ? (
                                  <TitleSkeleton />
                                ) : (
                                  <h3
                                    id="long-inspire-header"
                                    className="text-white mobile:py-[2.25rem] tablet:py-[1.5rem] desktop:py-[36px] mobile:text-4xl tablet:text-[40px] font-bold"
                                  >
                                    {content.header}
                                  </h3>
                                )}
                              </div>
                            </Grid>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </motion.div>
                <motion.div
                  style={useMediaQuery('(min-width: 1280px)') ? {} : {}}
                  id="long-inspire-left-answer-content"
                  className={classNames("text-text-greyHeader z-10 mobile:col-start-1 desktop:col-start-2 mobile:col-span-full desktop:col-span-8 mobile:pt-[36px] tablet:pt-[36px] desktop:pt-[2.25rem] mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0 desktop:flex desktop:flex-col desktop:pb-0", {
                    ["!text-white"]: version === "dark",
                    ["!text-text-greyText"]: version === "white" || version === "grey",
                  })}
                >
                  {!content.tagline ? (
                    <ParagraphSkeleton />
                  ) : (
                    <h3 id="long-inspire-paragraph-1" className="text-2xl font-light">
                      {content.tagline}
                    </h3>
                  )}
                </motion.div>

                <motion.div
                  style={useMediaQuery('(min-width: 1280px)') ? {} : {}}
                  id="long-inspire-right-answer-content"
                  className={classNames("text-text-greyText mobile:col-start-1 desktop:col-start-11 mobile:col-span-full desktop:col-end-24 w-full h-full mobile:pt-[2.25rem] tablet:pt-[1.375rem] desktop:pt-[2.25rem] mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0", {
                    ["!text-white"]: version === "dark",
                    ["!text-text-greyText"]: version === "white" || version === "grey",
                  })}
                >
                  <div className="">
                    {!content.paragraphs[0] ? (
                      <ParagraphSkeleton />
                    ) : (
                      <TypewriterText
                        id="long-inspire-paragraph-2"
                        className="mobile:text-sm desktop:text-lg font-light"
                        hasAllText={content.paragraphs[0].done}
                        text={content.paragraphs[0].text}
                        isDone={para1Done}
                        setIsDone={setPara1Done}
                      />
                    )}
                  </div>
                  {!content.paragraphs[1] ? (
                    <ParagraphSkeleton />
                  ) : (
                    para1Done && (
                      <div className="pt-[1.375rem]">
                        <TypewriterText
                          id="long-inspire-paragraph-2"
                          className="mobile:text-sm desktop:text-lg font-light"
                          hasAllText={content.paragraphs[1].done}
                          text={content.paragraphs[1].text}
                          isDone={para2Done}
                          setIsDone={setPara2Done}
                        />
                      </div>
                    )
                  )}
                </motion.div>
              </Grid>
              {para1Done && <Citations metadata={response.metadata} version={version} />}
              {para1Done && <MediaLinksV2 assets={assets} display="fullwidth" version={version} />}

            </motion.div>
            {/* <div className="desktop:grid grid-cols-24 absolute bottom-16 w-full">
              <div
                className={classNames("col-start-2 row-start-1 col-span-full", {
                  ["desktop:translate-y-0 desktop:transition-all desktop:duration-300 desktop:delay-100 desktop:visible"]:
                    para2Done,
                  ["desktop:translate-y-full desktop:invisible desktop:h-0"]:
                    !para2Done,
                })}
              >
                {version === "white" && (
                  <ButtonScrollDown
                    customClasses="relative"
                    versionColour="white"
                  />
                )}
                {version === "dark" && (
                  <ButtonScrollDown
                    customClasses="relative"
                    versionColour="dark"
                  />
                )}
                {version === "grey" && (
                  <ButtonScrollDown
                    customClasses="relative"
                    versionColour="grey"
                  />
                )}
              </div>                              
            </div> */}
          </div>
        </div>
        <div className={classNames({ ["!hidden"]: !para2Done })}>
          <motion.div
            ref={questionRef}
            style={useMediaQuery('(min-width: 1280px)') && para2Done && followUps.length > 0 ? { y: questionY } : {}}
            className={classNames(
              "mobile:rounded-t-3xl desktop:rounded-t-[1.5rem] w-full mobile:relative desktop:absolute z-40 mobile:-mt-[3.125rem] desktop:-mt-0",
              {
                ["hidden"]: hideQuestion,
              }
            )}>
            <QuestionBoxFullWidth
              templateOrderIndex={templateOrderIndex}
              style="lightTxt"
              backgroundColour="background-brand"
              followupQuestions={followUps}
              isLoading={!para2Done}
              onSubmit={askQuestion}
              giveFeedback={giveFeedback}
              optionQuestion={userQuestion}
              nextTemplate={nextTemplateRef}
              sethideQuestion={setHideQuestions}
              customMargin="!mx-0"
            />
          </motion.div>
        </div>
      </motion.div>
    );
  }
);
